<!--
* 创建人：罗兴
* 日 期：
* 描 述：学生个人信息
-->
<!--view 模板-->
<template>
  <div class="container">
    <el-button type="primary" @click="saveForm">保存</el-button>
    <el-scrollbar style="height: 90%; margin-top: 5px" class="myscrollbar">
      <div class="descriptions-body">
        <table class="descriptions-table">
          <tr>
            <td class="bordered-label">学号</td>
            <td class="descriptions-content">{{ baseinfo.xh }}</td>
            <td class="bordered-label">姓名</td>
            <td class="descriptions-content">{{ baseinfo.xm }}</td>
            <td
              class="descriptions-content"
              style="width: 150px; box-size: border-box"
              rowspan="4"
            >
              <el-upload
                class="avatar-uploader"
                :headers="headers"
                :action="uploadurl"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="baseinfo.imgurl"
                  :src="baseinfo.imgurl"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </td>
          </tr>
          <tr>
            <td class="bordered-label">性别</td>
            <td class="descriptions-content">
              {{ baseinfo.xbm == 1 ? '男' : '女' }}
            </td>
            <td class="bordered-label">出生日期</td>
            <td class="descriptions-content">
              {{ dateFormat2(baseinfo.csrq) }}
            </td>
          </tr>
          <tr>
            <td class="bordered-label">民族</td>
            <td class="descriptions-content">{{ baseinfo.mz }}</td>
            <td class="bordered-label">政治面貌</td>
            <td class="descriptions-content">{{ baseinfo.zzmm }}</td>
          </tr>
          <tr>
            <td class="bordered-label">籍贯</td>
            <td class="descriptions-content" colspan="3">
              <el-input v-model="baseinfo.jg" maxlength="50"></el-input>
            </td>
          </tr>
          <tr>
            <td class="bordered-label">家庭地址</td>
            <td class="descriptions-content" colspan="4">
              <el-select
                v-model="baseinfo.provinces"
                clearable
                @change="jtsfchange"
              >
                <el-option
                  v-for="(item, index) in jtsflist"
                  :key="index"
                  :label="item.province"
                  :value="item.province"
                ></el-option>
              </el-select>
              <el-select v-model="baseinfo.citiy" clearable @change="jtschange">
                <el-option
                  v-for="(item, index) in jtslist"
                  :key="index"
                  :label="item.city"
                  :value="item.city"
                ></el-option>
              </el-select>
              <el-select v-model="szx" clearable>
                <el-option
                  v-for="(item, index) in jtxlist"
                  :key="index"
                  :label="item.area"
                  :value="item.area"
                ></el-option>
              </el-select>
              <el-input
                v-model="baseinfo.detail"
                maxlength="50"
                style="width: 200px"
              ></el-input>
            </td>
          </tr>
          <tr>
            <td class="bordered-label">考生号</td>
            <td class="descriptions-content">{{ baseinfo.ksh }}</td>
            <td class="bordered-label">身份证号</td>
            <td class="descriptions-content" colspan="2">
              {{ baseinfo.sfzh }}
            </td>
          </tr>
          <tr>
            <td class="bordered-label">院系</td>
            <td class="descriptions-content">{{ baseinfo.bmmc }}</td>
            <td class="bordered-label">专业</td>
            <td class="descriptions-content" colspan="2">
              {{ baseinfo.zymc }}
            </td>
          </tr>
          <tr>
            <td class="bordered-label">班级</td>
            <td class="descriptions-content">{{ baseinfo.bjmc }}</td>
            <td class="bordered-label">年级</td>
            <td class="descriptions-content" colspan="2">
              {{ baseinfo.nj }}
            </td>
          </tr>
          <tr>
            <td class="bordered-label">入学年级</td>
            <td class="descriptions-content">{{ baseinfo.rxnj }}</td>
            <td class="bordered-label">毕业年份</td>
            <td class="descriptions-content" colspan="2">
              {{ baseinfo.bynf }}
            </td>
          </tr>
          <tr>
            <td class="bordered-label">在读状态</td>
            <td class="descriptions-content">在读</td>
            <td class="bordered-label">生源所在地</td>
            <td class="descriptions-content" colspan="2">
              {{ baseinfo.syszdmc }}
            </td>
          </tr>
          <tr>
            <td class="bordered-label">联系电话</td>
            <td class="descriptions-content">
              <el-input v-model="baseinfo.lxdh" maxlength="11"></el-input>
            </td>
            <td class="bordered-label">电子邮箱</td>
            <td class="descriptions-content" colspan="2">
              <el-input v-model="baseinfo.dzyx" maxlength="50"></el-input>
            </td>
          </tr>
          <tr>
            <td class="bordered-label">QQ</td>
            <td class="descriptions-content">
              <el-input v-model="baseinfo.qq" maxlength="15"></el-input>
            </td>
            <td class="bordered-label">房间</td>
            <td class="descriptions-content" colspan="2">
              <el-input v-model="baseinfo.fjbh" maxlength="10"></el-input>
            </td>
          </tr>
          <tr>
            <td class="bordered-label" style="color: #000" colspan="5">
              其他信息
            </td>
          </tr>
          <tr>
            <td class="bordered-label">勤工助学</td>
            <td class="descriptions-content" colspan="4">
              <div v-if="gw.length == 0">暂无数据</div>
              <el-table v-else :data="gw" border style="width: 100%">
                <el-table-column prop="gwmc" label="岗位名称" width="180">
                </el-table-column>
                <el-table-column prop="xn" label="学年" width="180">
                </el-table-column>
                <el-table-column prop="bmmc" label="单位"> </el-table-column>
                <el-table-column prop="gwfzr" label="岗位负责人">
                </el-table-column>
                <el-table-column prop="dgsj" label="到岗时间">
                  <template slot-scope="scope">
                    {{ dateFormat2(scope.row.dgsj) }}
                  </template>
                </el-table-column>
                <el-table-column prop="lgsj" label="离岗时间">
                  <template slot-scope="scope">
                    {{ dateFormat2(scope.row.lgsj) }}
                  </template>
                </el-table-column>
                <el-table-column prop="zt" label="状态">
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.zt == 0" type="danger">离岗</el-tag>
                    <el-tag v-if="scope.row.zt == 1" type="success"
                      >在岗</el-tag
                    >
                  </template>
                </el-table-column>
              </el-table>
            </td>
          </tr>
          <tr>
            <td class="bordered-label">违纪情况</td>
            <td class="descriptions-content" colspan="4">
              <div v-if="wj.length == 0">暂无数据</div>
              <el-table v-else :data="wj" border style="width: 100%">
                <el-table-column prop="cffwbh" label="发文编号" width="180">
                </el-table-column>
                <el-table-column prop="wjsj" label="违纪时间" width="180">
                  <template slot-scope="scope">
                    {{ dateFormat2(scope.row.wjsj) }}
                  </template>
                </el-table-column>
                <el-table-column prop="rdjgmc" label="认定结果">
                </el-table-column>
                <el-table-column prop="cfjgmc" label="处分结果">
                </el-table-column>
                <el-table-column prop="sfjc" label="到岗时间">
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.sfjc == 0" type="danger">否</el-tag>
                    <el-tag v-if="scope.row.sfjc == 1" type="success"
                      >是</el-tag
                    >
                  </template>
                </el-table-column>
                <el-table-column prop="xq" label="学期"> </el-table-column>
              </el-table>
            </td>
          </tr>
          <tr>
            <td class="bordered-label">家庭经济困难认定</td>
            <td class="descriptions-content" colspan="4">
              <div v-if="kns.length == 0">暂无数据</div>
              <el-table v-else :data="kns" border style="width: 100%">
                <el-table-column prop="xn" label="学年"> </el-table-column>
              </el-table>
            </td>
          </tr>
          <tr>
            <td class="bordered-label">助学贷款</td>
            <td class="descriptions-content" colspan="4">
              <div v-if="zxdk.length == 0">暂无数据</div>
              <el-table v-else :data="zxdk" border style="width: 100%">
                <el-table-column prop="xsxn" label="学年"> </el-table-column>
                <el-table-column prop="zxdkje" label="助学贷款金额">
                </el-table-column>
              </el-table>
            </td>
          </tr>
          <tr>
            <td class="bordered-label">国家助学金</td>
            <td class="descriptions-content" colspan="4">
              <div v-if="zxj.length == 0">暂无数据</div>
              <el-table v-else :data="zxj" border style="width: 100%">
                <el-table-column prop="xn" label="学年"> </el-table-column>
              </el-table>
            </td>
          </tr>
          <tr>
            <td class="bordered-label">单项积极分子</td>
            <td class="descriptions-content" colspan="4">
              <div v-if="dx.length == 0">暂无数据</div>
              <el-table v-else :data="dx" border style="width: 100%">
                <el-table-column prop="xn" label="学年"> </el-table-column>
                <el-table-column prop="rych" label="荣誉称号">
                </el-table-column>
              </el-table>
            </td>
          </tr>
          <tr>
            <td class="bordered-label">三好学生</td>
            <td class="descriptions-content" colspan="4">
              <div v-if="shxs.length == 0">暂无数据</div>
              <el-table v-else :data="shxs" border style="width: 100%">
                <el-table-column prop="xn" label="学年"> </el-table-column>
                <el-table-column prop="id" label="荣誉称号">
                  <template> 三好学生 </template>
                </el-table-column>
              </el-table>
            </td>
          </tr>
          <tr>
            <td class="bordered-label">学生干部</td>
            <td class="descriptions-content" colspan="4">
              <div v-if="shxs.length == 0">暂无数据</div>
              <el-table v-else :data="xsgb" border style="width: 100%">
                <el-table-column prop="xn" label="学年"> </el-table-column>
                <el-table-column prop="id" label="荣誉称号">
                  <template> 优秀学生干部 </template>
                </el-table-column>
              </el-table>
            </td>
          </tr>
        </table>
      </div>
    </el-scrollbar>
  </div>
</template>
<!-- javascript脚本-->
<script>
import {
  getAllPersonInfo,
  saveXSInfo,
  GetSF,
  GetSZS,
  GetSZX,
} from '../../api/jcxx'
import { baseUrl } from '../../utils/http'
import store from '../../store/index'
import moment from 'moment'
export default {
  data() {
    return {
      baseinfo: {},
      uploadurl: baseUrl + 'File/uploadImg',
      headers: null,
      baseurl: baseUrl,
      gw: [],
      wj: [],
      kns: [],
      zxdk: [],
      zxj: [],
      dx: [],
      shxs: [],
      xsgb: [],
      jtsflist: [],
      jtslist: [],
      jtxlist: [],
      szx: '',
    }
  },
  created() {
    this.headers = {
      Authorization: 'Bearer ' + store.state.token.access_token,
    }
    getAllPersonInfo().then((res) => {
      if (res.code === 200) {
        this.baseinfo = res.data.baseinfo
        this.szx = this.baseinfo.area
        this.gw = res.data.gw
        this.wj = res.data.wj
        this.kns = res.data.kns
        this.zxdk = res.data.zxdk
        this.zxj = res.data.zxj
        this.dx = res.data.dx
        this.shxs = res.data.shxs
        this.xsgb = res.data.xsgb
        if (res.data.baseinfo.imgurl) {
          this.baseinfo.imgurl = res.data.baseinfo.imgurl
        }
        GetSF().then((res) => {
          if (res.code === 200) {
            this.jtsflist = res.data
            GetSZS({ sfmc: this.baseinfo.provinces }).then((res) => {
              this.jtslist = res.data
              if (res.code === 200) {
                GetSZX({ smc: this.baseinfo.citiy }).then((res) => {
                  if (res.code === 200) {
                    this.jtxlist = res.data
                  }
                })
              }
            })
          }
        })
      }
    })
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.baseinfo.imgurl = this.baseurl + res.data.filePath
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY-MM-DD')
    },
    // 保存信息
    saveForm() {
      if (this.baseinfo.lxdh) {
        var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/
        if (!myreg.test(this.baseinfo.lxdh)) {
          this.$message.error('手机号格式不正确')
          return false
        }
      }
      if (this.baseinfo.dzyx) {
        var re =
          /^(([^()[\]\\.,;:\s@]+(\.[^()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        if (!re.test(this.baseinfo.dzyx)) {
          this.$message.error('邮箱格式不正确')
          return false
        }
        this.baseinfo.area = this.szx
        saveXSInfo({ xs: this.baseinfo }).then((res) => {
          if (res.code === 200) {
            this.$message.success(res.message)
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    jtsfchange() {
      this.baseinfo.citiy = ''
      this.szx = ''
      GetSZS({ sfmc: this.baseinfo.provinces }).then((res) => {
        if (res.code === 200) {
          this.jtslist = res.data
        }
      })
    },
    jtschange() {
      this.szx = ''
      GetSZX({ smc: this.baseinfo.citiy }).then((res) => {
        if (res.code === 200) {
          this.jtxlist = res.data
        }
      })
    },
  },
  mounted() {},
}
</script>
<!-- CSS样式 -->
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
}
.my-label {
  margin: 0;
  padding: 0;
  width: 0 !important;
}
.descriptions-body {
  color: #606266;
  background-color: #fff;
  font-size: 14px;
}
.descriptions-table {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  display: table;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: grey;
}
.descriptions-table tr {
  display: table-row;
  border-color: inherit;
}
.descriptions-table th,
.descriptions-table td {
  padding-bottom: 12px;
}
.bordered-label {
  border: 1px solid #ebeef5;
  padding: 12px 10px;
  font-weight: 700;
  color: #909399;
  background: #fafafa;
  box-sizing: border-box;
  text-align: left;
  line-height: 1.5;
  width: 200px;
}
.descriptions-content {
  border: 1px solid #ebeef5;
  padding: 12px 10px;
  box-sizing: border-box;
  text-align: left;
  font-weight: 400;
  line-height: 1.5;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 126px;
  height: 180px;
  line-height: 180px;
  text-align: center;
}
.avatar {
  width: 126px;
  height: 180px;
  display: block;
}
</style>
